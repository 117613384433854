import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { take } from "lodash";
import Layout from "../../components/layouts";
import { Container } from "../../components/layouts/components/Container";
import { CaseStudy as CaseStudyItem } from "../../components/case-study";
import FormPanelDesktopComponent from "../../components/layouts/FormPanelDesktopComponent";
import { RichText } from "prismic-reactjs";
import { format } from "date-fns";
import ShareSocial from "./ShareSocial";
import SEO from "../../templates/seo";
import Slice from "../../components/SliceZone";
import logo from "../../images/logo.svg";
import { LustriaH1, LustriaH2 } from "../../components/text";
import { canonicalUrlResolver } from "../../utils/routeHelper";

export const query = graphql`
  query CaseStudyPostQuery($uid: String!) {
    prismic {
      case_study(lang: "en-au", uid: $uid) {
        title
        subtitle
        published_date
        featured_image
        author_name
        read_time
        _meta {
          uid
        }
        seo {
          seo_description
          seo_image
          seo_title
          keywords
          business_information {
            ... on PRISMIC_Business_information {
              business_name
              logo
              address
              city
              country
              description
              state_region
              telephone
              url
              zip
            }
          }
        }
        related_case_studies {
          related_case_study {
            ... on PRISMIC_Case_study {
              title
              featured_image
              published_date
              subtitle
              _meta {
                tags
                uid
              }
            }
          }
        }
        body {
          ... on PRISMIC_Case_studyBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_Case_studyBodyVideo_panel {
            type
            label
            primary {
              theme
              video_link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              video_title
              video_title_emphasis
            }
          }
          ... on PRISMIC_Case_studyBodyCard_panel {
            type
            label
            primary {
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              bullet_points
              card_panel_image
              description
              image_location
              linktext
              subtitle
              title
            }
          }
          ... on PRISMIC_Case_studyBodyCall_to_action {
            type
            label
            primary {
              fixed_color
              image
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              link_text
              subtitle
              title1
              cta_type
            }
          }
          ... on PRISMIC_Case_studyBodyFrequent_asked_question {
            type
            label
            fields {
              answer
              question
            }
          }
          ... on PRISMIC_Case_studyBodyPull_quote {
            type
            label
            primary {
              author
              background_color
              quote
            }
          }
          ... on PRISMIC_Case_studyBodyImage_panel {
            type
            label
            primary {
              image
              subtitle
            }
          }
          ... on PRISMIC_Case_studyBodyExpression_of_interest {
            type
            label
            primary {
              show_eoi_form
            }
          }
          ... on PRISMIC_Case_studyBodyOur_team {
            type
            label
            primary {
              title
            }
            fields {
              avatar
              description
              full_name
              job_title
              url_destination
              url_display_text
              url_icon
            }
          }
          ... on PRISMIC_Case_studyBodyClient_logos {
            type
            label
            primary {
              title
            }
            fields {
              logo
              client_website
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const CaseStudy = ({ data, location }) => {
  const caseStudy = data.prismic.case_study;

  if (!caseStudy) return null;

  let contentTitle = RichText.asText(caseStudy.title);
  const relatedCaseStudies = caseStudy.related_case_studies.filter((x) => x.related_case_study);

  const url = canonicalUrlResolver(location?.pathname);
  
  return (
    <Layout canonicalUrl={url}>
      {caseStudy.seo.length && (
        <SEO
          seo={caseStudy?.seo}
          siteUrl={data.site?.siteMetadata?.siteUrl}
          author={caseStudy?.author_name}
          publishDate={caseStudy?.published_date}
        />
      )}
      <ContentContainer
        className="d-flex align-items-center flex-column"
        aria-label={contentTitle}
        role="region"
      >
        <CaseStudyTitle>{contentTitle}</CaseStudyTitle>
        <div className="d-none d-md-block mb-4">
          <img src={logo} alt="Skildare logo" />
        </div>
        <SubSection>
          <p className="mb-2">{RichText.asText(caseStudy.read_time)} read</p>
          <p className="m-0">
            {caseStudy.author_name} |{" "}
            {format(new Date(caseStudy?.published_date), "PPP")}
          </p>
        </SubSection>

        <FeaturedImageWrapper>
          <img
            src={caseStudy.featured_image.url}
            alt="test"
            width="100%"
            height="100%"
          />
        </FeaturedImageWrapper>
        <CaseStudyContentWrapper>
          <SubTitle>{RichText.asText(caseStudy?.subtitle)}</SubTitle>
          <div className="text-center d-none d-md-block my-4">
            <img width="54px" height="54px" src={logo} alt="Skildare logo" />
          </div>
        </CaseStudyContentWrapper>
      </ContentContainer>

      {caseStudy.body?.map((slice, index) => (
        <div style={{ marginBottom: -40 }}>
          <Slice slice={slice} caseStudy={true} index={index} />
        </div>
      ))}

      <ContentContainer>
        <ShareSocial />
        {relatedCaseStudies.length > 0 ? (
          <>
            <DividerHR />
            <RelatedArticles>More case studies</RelatedArticles>
            <div class="row pb-5">
              {take(relatedCaseStudies, 2)?.map(
                ({ related_case_study }) => {
                  const rCaseStudy = {
                    ...related_case_study,
                    slug: related_case_study?._meta?.uid,
                    tags: related_case_study?._meta?.tags,
                  };

                  return (
                    <RelatedCaseStudyWrapper className="col-md-6">
                      <CaseStudyItem {...rCaseStudy} />
                    </RelatedCaseStudyWrapper>
                  );
                }
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </ContentContainer>

      <FooterWrapper>
        <FormPanelDesktopComponent />
      </FooterWrapper>
    </Layout>
  );
};

const FooterWrapper = styled.div`
  padding-bottom: 48px;
`;
const ContentContainer = styled(Container)`
  margin-top: 48px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 120px 24px !important;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  @media (min-width: 992px) {
    padding: 0 0 48px;
  }
`;

const DividerHR = styled.hr`
  margin: 32px 0;
`;

const RelatedArticles = styled.h3`
  font-family: Lustria;
  font-size: 25px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColors.steelBlue};
`;

const RelatedCaseStudyWrapper = styled.div`
  margin-top: 32px;
`;

const CaseStudyTitle = styled(LustriaH1)`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

const SubSection = styled.div`
  color: ${({ theme }) => theme.colors.greySecondary};
  opacity: 0.7;
  margin-bottom: 48px;

  p {
    text-align: center;
    font-family: OpenSans;
  }
`;

const FeaturedImageWrapper = styled.div`
  img {
    object-fit: cover;
    background-position: center;
    background-size: cover;
  }

  width: 100%;
  @media (min-width: 992px) {
    height: 500px;
  }

  margin-bottom: 48px;
`;

const CaseStudyContentWrapper = styled.div`
  @media (min-width: 992px) {
    padding: 0 100px;
  }
`;

const SubTitle = styled(LustriaH2)`
  margin-bottom: 48px;
  line-height: 37px;
  color: ${({ theme }) => theme.colors.grey};
`;

export default CaseStudy;
